












import Vue from 'vue'

export default Vue.extend({
  computed: {
    phoneLink(): string {
      const phone = this.$i18n.t('phoneNumber') as string
      return `tel:+34${phone.replace(/ /g, '')}`
    },
  },
  methods: {
    sendGtmEvent(): void {
      if (this.$responsive.isMobile) {
        this.$pushGtm4Event({
          event: 'c2c',
          page_type: 'cambiarcompanialuz',
          position: 'body1',
          cta_name: undefined,
          link_text: `${this.$i18n.t('callUs')} ${this.$i18n.t('phoneNumber')}`,
          link_url: this.phoneLink,
        })
        this.$pushGtmEvent({
          eventCategory: 'cambiarcompanialuz',
          eventAction: 'body',
          eventLabel: this.phoneLink,
        })
      }
    },
  },
})
